import React, { useEffect } from 'react'

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className='bg-[#edeef0]'>
        <div className='container py-20 md:pt-32 md:pb-12'>
            <div className='lg:w-[65%] mx-auto'>
                <p className='mb-8 text-4xl font-bold text-center md:text-5xl'>Terms & Conditions</p>

                <div>
                    <p className='mb-2 text-base italic text-gray-700'>Updated 01/08/2022</p>
                </div>

                <p className='mb-6 text-base'>This page describes the terms and conditions that apply to your access and use of the Bird mobile application. 
                    Please carefully read these Terms and Conditions. You agree to be bound by these Terms and Conditions by using our Services.
                </p>

                <p className='mb-6 text-base'>For the purposes of these Terms and Conditions, Applicable Law means applicable laws, rules, codes, regulations, formal regulatory guidelines and standards, 
                    made by a regulator, legislature or other public authority with binding effect in force from time to time (construed having 
                    regard to related guidance and codes of practice issued or approved by a regulator or other public body) and applicable to a party and relevant to these Terms and Conditions.
                </p>

                <div>
                    <p className='mb-1 text-xl font-bold'>Breaches</p>
                    <p className='mb-2 text-base'>Without limiting Bird's other remedies, 
                            if we have reasonable grounds to believe that you are in breach of any of these Terms and Conditions, we reserve the right to take whatever action we deem appropriate, including:                
                    </p>
                    <ul className='pl-10 mb-6 text-base list-disc'>
                        <li>suspending or terminating your user profile; and</li>
                        <li>imposing other restrictions on your use of the Services, at our sole discretion.</li>
                    </ul>

                    <p className='mb-6 text-base'>Bird reserves the right to cooperate fully with governmental authorities, law enforcement bodies and/or injured third parties in the investigation of any suspected criminal or 
                    civil wrongdoing. To the extent permitted by Applicable Law and policies, Bird may disclose your identity and/or information relating to your user profile, transactions or activities if requested by a governmental authority or an injured 
                    third party or as a result of legal action. Bird shall not be liable for damages arising from such disclosure, and you agree not to bring any action or claim against Bird for such disclosure.
                    </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Liability</p>
                    <p className='mb-2 text-base'>The Services and all of its components are provided “as is” without any warranty, neither express nor implied, of any kind whatsoever.
                        To the maximum extent permitted by Applicable Law, and except for damages resulting from Bird’s fraud or willful misconduct, 
                        Bird (including its affiliates, directors, agents and employees) shall not be liable, in contract, tort (including negligence) or otherwise, for any losses or damages resulting from:              
                    </p>
                    <ul className='pl-10 mb-6 text-base list-disc'>
                        <li>glitches, bugs, delays or disruptions in the Services;</li>
                        <li>viruses or other malicious software obtained by using the Services;</li>
                        <li>inaccuracies, errors or omissions in the information made available through the Services;</li>
                        <li>any defect in goods or services ordered or obtained from any third party through the Services;</li>
                        <li>unauthorized access or modification by third parties of any content or information stored on our websites or applications; and</li>
                        <li>any matters relating to the Services, your access to and use of the Services or your inability to use or access the Services.</li>
                    </ul>

                    <p className='mb-6 text-base'>Although we have processes in place aimed at verifying the accuracy of information provided by our users,
                     Bird is not responsible for ensuring the accuracy, truthfulness of users’ purported identities, or the validity of the content that they provide.
                    </p>

                    <p className='mb-6 text-base'>Bird shall not be liable for any special, incidental, indirect, punitive or consequential loss or damage whether arising from negligence, breach of these Terms and Conditions or
                     otherwise, including without limitation loss of revenue, loss of profits, loss of opportunities, loss of anticipated savings and loss of reputation.
                    </p>
                    
                    <p className='mb-6 text-base'>
                        Regardless of the preceding paragraphs, if we are found to be liable, our liability to you or any third party is limited to $25.00.
                    </p>
                    
                    <p className='mb-6 text-base'>Nothing in these Terms and Conditions shall limit or exclude Bird’s liability for fraudulent misrepresentation, for death or 
                    personal injury resulting from the negligence of our agents or employees, or for any other liability that cannot be limited or excluded by law.
                    </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Indemnification</p>
                    <p className='mb-2 text-base'>To the extent allowed under Applicable Laws, you agree to compensate us 
                    (and our affiliates, directors, agents and employees) in full for any losses or costs, including reasonable legal fees, incurred as a result of:                
                    </p>
                    <ul className='pl-10 mb-6 text-base list-disc'>
                        <li>your breach of these Terms and Conditions;</li>
                        <li>your improper use of the Services; or</li>
                        <li>your breach of any Applicable Law or the rights of any third party.</li>
                    </ul>

                </div>

                <div>
                    <p className='mb-1 text-xl font-bold'>Alterations to the Terms and Conditions</p>
                    <p className='mb-6 text-base'>We reserve the right to change these Terms and Conditions at any time. In such a case, 
                    we will notify you before the amended Terms and Conditions take effect. Your continued use of the Services following this notice period will be deemed acceptance of the amended Terms and Conditions.                
                    </p>
                </div>

                <div>
                    <p className='mb-1 text-xl font-bold'>Waiver</p>
                    <p className='mb-6 text-base'>Failure by us to exercise or enforce any right or provision of these Terms and Conditions does not constitute a waiver of that right or provision. 
                    Any waiver of any right or provision of these Terms and Conditions is only effective if in writing and signed by an authorized representative of Bird.               
                    </p>
                </div>

                <div>
                    <p className='mb-1 text-xl font-bold'>Complaints</p>
                    <p className='text-base'>Please contact us at <a href='mailto:help@Birdnet.io' className='font-bold text-primary' target="blank">help@Birdnet.io</a> if you have any questions or suggestions about our Terms and Conditions.               
                    </p>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Terms