import React from 'react';
import './App.css';
import {Routes, Route} from "react-router-dom"
import Home from './pages/Home';
import Layout from './component/Layout';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import NewLayout from './component/NewLayout';
import ResetPassword from './pages/ResetPassword';

function App() {
  return (
    <Routes>

<Route path="/" element={<NewLayout />}>
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/reset-password" element={<ResetPassword />} />

      </Route>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        
      </Route>
    </Routes>
  );
}

export default App;
