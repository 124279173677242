import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import PlaceholderImg from "../../img/checkout.svg";
import XIcon from "../../img/close-icon.svg";


const CheckOutSection = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const form = useRef();


  const handleChange = (event) => {
    event.target.name ==="firstName" 
    ? setFirstName(event.target.value)
    : event.target.name ==="lastName" 
    ? setLastName(event.target.value)
    : event.target.name ==="email" 
    ? setEmail(event.target.value)
    : toast.error("wrong fields");
  };
 
  // const handlePhoneChange = (num) => {
  //   console.log(num)
  // }
  
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true) 
    emailjs.sendForm('service_1hpfyal', 'template_wvnd9xe', e.target,'5M21M_NG9rBIKAfY0').then((res) => {
      toast.success("Your  request has been sent successfully!");
      setEmail('')
      setFirstName('')
      setLastName('')
      setPhone('')
      setLoading(false) 
  }, (error) => {
    toast.error("Upps, someting went wrong. Try again later");
  });
}
 

  const cancelButtonRef = useRef(null)
  
  return (
    <>
    <div className="bg-[#F9FAFB]" id="checkout">
      <div className="container py-5 md:py-12">
        <div className="flex flex-col items-center justify-between gap-8 lg:flex-row">
          <div className="lg:w-1/2">
            <div className="xl:ml-20">
              <img
                src={PlaceholderImg}
                alt="placeholder"
                srcSet=""
                data-aos={"fade-right"}
                data-aos-delay={50}
              />
            </div>
          </div>

          <div className="lg:w-1/2">
            <span className="px-3 py-1 inline-block rounded-full text-[#1A6533] mb-4 bg-[#EAF6EE] text-sm font-[500]">
            Coming Soon
            </span>
            <h2 className="md:text-5xl text-3xl font-bold lg:w-[80%] tx-moderat">
              Checkout with BirdNet
            </h2>
            <div className="lg:w-[85%]">
              <p className="my-8 md:text-2xl text-lg text-[#4A5C6B]">
                You can integrate the BirdNet fast checkout controls into your
                website or app. It accepts local and international payments for
                businesses of all sizes.
              </p>

              <div className="mt-4">
              <button
                            type="submit"
                            className="inline-flex justify-center w-full md:w-[60%] px-4 py-2.5 text-base font-medium text-white border border-transparent rounded-full shadow-sm bg-primary500 hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                            onClick={() => setOpen(true)}
                          >
                           Get Notified
                      
                          </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

    {/* modal  */}
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full md:max-w-lg sm:p-6">
                <div>
                <div className='flex items-center justify-between'>
                  <div>
                    <h5 className="text-lg font-medium">Be the first to know</h5>
                  </div>
                  < div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <img src={XIcon} className="w-3 h-3" aria-hidden="true" alt='close' />
                  </button>
                </div>
                </div>
                
                  <div className="mt-3 sm:mt-5">
                    
                    <div className="">
                      <form  onSubmit={sendEmail}>
                        <div>
                          <div className="flex gap-4">
                            <div>
                            <span className="text-sm  text-[#344054]">First name</span>
                              <input className="w-full p-3 mt-1 text-gray-900 border rounded-lg focus:outline-none focus:shadow-outline"
                              type="text"
                              name='firstName'
                              value={firstName}
                              onChange={(e) => handleChange(e)}
                              placeholder="First name"/>
                            </div>
                            <div>
                            <span className="text-sm  text-[#344054]">Last name</span>
                            <input className="w-full p-3 mt-1 text-gray-900 border rounded-lg focus:outline-none focus:shadow-outline"
                              type="text"
                              name='lastName'
                              value={lastName}
                              onChange={(e) => handleChange(e)}
                              placeholder="Last name"/>
                            </div>
                          </div>
                          
                        </div>
                        <div className="mt-4">
                          <span className="text-sm  text-[#344054]">Email</span>
                          <input className="w-full p-3 mt-1 text-gray-900 border rounded-lg focus:outline-none focus:shadow-outline"
                            type="text"
                            value={email}
                            onChange={(e) => handleChange(e)}
                            name='email'/>
                        </div>
                        <div className="mt-4">
                          <span className="text-sm  text-[#344054] mb-1 inline-block">Phone Number (optional)</span>
                            <PhoneInput
                             inputProps={{
                              name: 'phone',
                              autoFocus: true
                            }}
                              country={'us'}
                              value={phone}
                              onChange={(phone) => setPhone(phone)}
                            />
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="submit"
                            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-full shadow-sm bg-primary500 hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                          >
                              {
                            loading ? 'Submitting ...' : 'Submit'
                          }
                      
                          </button>
                          <button
                            type="button"
                            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

    <ToastContainer />
    
    </>
  );
}

export default CheckOutSection