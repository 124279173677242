import React, { useEffect } from 'react'
import {Link} from "react-router-dom"
const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='pt-20 md:pt-20'>
    <div className="relative flex items-center justify-center w-full h-screen text-center bg-center bg-cover blog-bg">
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-gray-900 opacity-75"></div>
      
      <div className="z-50 flex flex-col items-center justify-center w-full h-screen text-white">
    <h1 className="mb-8 text-5xl">We are <b>Almost</b> there!</h1>
  <p className='text-lg'>Stay tuned for something amazing!!!</p>
  
  <Link to="/" className="z-0 px-4 py-3 mt-6 rounded bg-primary500 hover:bg-primary">Go back to Home Page</Link>
  
  
    
    </div>
  
</div>

    </div>
  )
}

export default Blog