import React from "react";
import { Outlet } from "react-router-dom";
import PseudoNav from "./PseudoNav";
import Footer from "./Footer";

const NewLayout = () => {
  return (
    <div className="bg-white min-h-screen">
      <PseudoNav />
      <Outlet />
      <Footer />
    </div>
  );
};

export default NewLayout;
