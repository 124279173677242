import React, { useEffect } from 'react'

const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className='bg-[#edeef0]'>
        <div className='container py-20 md:pt-32 md:pb-12'>
            <div className='lg:w-[65%] mx-auto'>
                <p className='mb-8 text-4xl font-bold text-center md:text-5xl'>Privacy Policy</p>

                <div>
                    <p className='mb-2 text-base italic text-gray-700'>Updated 13/10/2022</p>
                </div>
                <div>
                    <p className='mb-1 text-xl font-bold'>Overview:</p>
                    <p className='mb-6 text-base'>This page is intended to inform visitors about our policies regarding the collection, use, and disclosure of Personal Information if they choose to use our Service. If you use our Service, you agree to the collection and use of information in accordance with this policy. We collect Personal Information in order to provide and improve the Service. Except as described in this Privacy Policy, we will not use or share your information with anyone. 
                    Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as those in our Terms and Conditions, which are available at Bird.
                    </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Information Collection and Use:</p>
                    <p className='mb-6 text-base'>
                    While using our Service, we may require you to provide us with certain personally identifiable information, such as your email address, phone number, and image in order to provide you with a better experience.
                     We will use your device's camera to take your profile photo if you authorize it. Your image will be saved on our server. 
                     We will upload your contact list information so that you can easily send money to Bird users, top-up airtime or data for any of your contacts using their phone numbers. 
                     By downloading the App, you confirm that you have read, understood and accept the terms of this Policy set out hereunder. You also consent to the collection, use, storage, processing and disclosure of your personal information in the manner set out in this Policy.
                     {/* We will also keep the information you provide and use it as described in this privacy statement. This platform does make use of third-party services, which may collect information that can be used to identify you. 
                    Link to the privacy policies of the platform's third-party service providers. */}
                    </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Log Data:</p>
                    <p className='mb-6 text-base'>
                    We want to inform you that whenever you use our Service, in the event of a platform error, we collect data and information (via third-party products) on your phone known as Log Data. This Log Data may include information such as your device's Internet Protocol ("IP") address,
                     device name, operating system version, platform configuration when using our Service, time and date of use, and other statistics.
                    </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Cookies:</p>
                    <p className='mb-6 text-base'>
                        Cookies are small data files that are commonly used as anonymous unique identifiers. These are sent to your browser by the websites you visit and are stored in the internal memory of your device.
                        These "cookies" are not explicitly used by this Service. The platform may, however, use third-party code and libraries that use "cookies" to collect information and improve their services. 
                        You have the option to accept or reject these cookies and to be notified when a cookie is sent to your device. If you choose to reject our cookies, you may be unable to use certain features of this Service.
                    </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Service Providers</p>
                    <p className='mb-6 text-base'>
                        We may use third-party companies and individuals for the following purposes: to facilitate our Service; to provide the Service on our behalf; to perform Service-related services; or to assist us in analyzing how our Service is used.
                        We want users of this Service to be aware that these third parties have access to their Personal Information. 
                        The reason for this is so that they can complete the tasks assigned to them on our behalf. They are, however, required not to disclose or use the information for any other purpose.
                    </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Security:</p>
                    <p className='mb-6 text-base'>
                    We value your trust in providing us with your Personal Information, so we strive to protect it using commercially acceptable means. 
                    However, keep in mind that no method of internet transmission or electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                    </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Links to Other Sites:</p>
                    <p className='mb-6 text-base'>
                    This Service may include links to other websites. You will be directed to a third-party website if you click on a third-party link. Please keep in mind that we do not operate these external websites. As a result, we strongly recommend that you read the Privacy Policies of these websites. 
                    We have no control over, and accept no responsibility for, any third-party sites or services' content, privacy policies, or practices.                   
                     </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Children’s Privacy:</p>
                    <p className='mb-6 text-base'>
                    These Services are not intended for children under the age of 13. We do not collect personally identifiable information from children under the age of 13. If we discover that a child under the age of 13 has provided us with personal information, we immediately remove it from our servers. 
                    If you are a parent or guardian and are aware that your child has provided us with personal information, please contact us so that we can take the appropriate action.
                    </p>
                </div>
                
                <div>
                    <p className='mb-1 text-xl font-bold'>Changes to This Privacy Policy:</p>
                    <p className='mb-6 text-base'>
                    We reserve the right to change our Privacy Policy at any time. As a result, you should check this page for updates on a regular basis. 
                    Any changes will be communicated to you by posting the new Privacy Policy on this page. This policy goes into effect on August 01, 2022.
                    </p>
                </div>

                <div>
                    <p className='mb-1 text-xl font-bold'>Contact Us:</p>
                    <p className='text-base'>Please contact us at <a href='mailto:help@Birdnet.io' className='font-bold text-primary' target="blank">help@Birdnet.io</a> if you have any questions or suggestions about our privacy policy.               
                    </p>
                </div>
                
                
                
            </div>
        </div>
    </div>
  )
}

export default Privacy