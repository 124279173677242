import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../img/Logo.svg";
const baseUrl = "http://ec2-13-58-247-253.us-east-2.compute.amazonaws.com:30103/api/v1/onboarding/reset-password";


const ResetPassword = () => {
  const location = useLocation(); 
  console.log(location);
  
  const history = useNavigate()
;  const [inValidUser, setInvalidUser] = useState(' ');
  const [busy, setBusy] = useState(true);
  const [newPassword, setNewPassword] = useState({
    password: "", 
    confirmPassword: "",
  });
  const [err, setError] = useState("");
  const [success, setSuccess] = useState(false);

const { token, id } = queryString.parse(location.search);

  const verifyToken = async () => {

    try {
       const { token } = queryString.parse(location.search);
       const { data } = await axios(
         `${baseUrl}/${token}`
       );
      setBusy(false);

      if (data.success) {
        setSuccess(true)
        history.replace('./reset-password')
      }
       
    } catch (err) {
    if (err?.response?.data) {
        const {data} = err.response
       if(!data.success) return setInvalidUser(data.err)

        return console.log( err.response.data);
    } else if (err.request) {
        // The request was made but no response was received
        // Error details are stored in err.reqeust
        console.log(err.request);
    } else {
        // Some other errors
        console.log('Error', err.message);
    }
}
  }

  useEffect (() =>{
    verifyToken();
  }, []);

  
  const handleSubmit = async (e) => {
    e.preventDefault();

   if (newPassword.password !== newPassword.confirmPassword) {
      return setError("Password does not match!");
    };
  }
  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    setNewPassword({
      ...newPassword, 
      [name]: value,
    });
  };

  if(success) 
  return (
    <div className="max-w-screen-sm m-auto pt-50">
      <h1 className="mb-4 text-3xl text-center text-gray-600">Password Reset Successfully</h1>
    </div>
  );

  if(inValidUser)
  return (
      <div className="max-w-screen-sm m-auto pt-50">
        <h1 className="mb-4 text-3xl text-center text-gray-600">
          {inValidUser}
        </h1>
      </div>
  );

  return (   
    <div className="flex min-h-full">
      <div className="flex flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-20">
        <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
          <div className="flex justify-center pt-20 min-h-[90vh] md:items-center md:pt-0">
            <div className="w-full">
              <div>
                <img className="w-auto h-12" src={logo} alt="logo" />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Reset Password
                </h2>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  {/* form section  */}
                  <form onSubmit={handleSubmit} method="" className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        New Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="password"
                          type="password"
                          placeholder="*******"
                          // value={email}
                          onChange={handleOnChange}
                          required
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary500 focus:border-primary500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="confirmOassword"
                          // value={password}
                          onChange={handleOnChange}
                          placeholder="*******"
                          required
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary500 focus:border-primary500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green500"
                      >
                        Proceed
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex-1 hidden w-0 lg:block">
        <img
          className="absolute inset-0 object-cover w-full h-full"
          src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt=""
        />
      </div>
    </div>
  );
};

export default ResetPassword;
